<template>
  <div class="email" >
    <img src="../assets/fxemoji_email.png" srcset="2x ../assets/fxemoji_email@2x.png" class="email-icon" alt="">
    <div class="email-con">
      <div class="send" v-if="!hasSend">
        <div class="send-tip">{{$t('emailPanel.verifyTip')}}</div>
        <div class="send-tip_2">{{$t('emailPanel.verifyTip_2')}}</div>
        <input type="text" v-model="email" class="input" :placeholder="$t('emailPanel.placeholder')">
        <div class="btn" v-loading="loading" @click="sendEmail" >{{$t('emailPanel.verifyBtn')}}</div>
      </div>
      <div class="tip" v-else>
        <div class="text">{{$t('emailPanel.sendTip')}} {{email}}</div>
        <div class="btn_2" @click="reSend">{{$t('emailPanel.reSendTip')}}</div>
        <div class="btn" @click="update">{{$t('emailPanel.varifyUpdate')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$on('show', () => { this.show = true })
  },
  data() {
    return {
      email: '',
      loading: false,
      hasSend: false,
      change: false,
    }
  },
  methods: {
    reSend() {
      this.hasSend = false
      this.email = ''
    },
    update() {
      this.getUserInfo().then((res) => {
        if (res.email_is_verify) {

        }
      })
    },
    sendEmail() {
      if (this.loading) return
      if (!this.email || !/^[\w-.]+@([\w-]+\.)+[\w-]+$/ig.test(this.email)) {
        this.$toast({
          msg: this.$i18n.t('emailPanel.emailVarifyErr'),
        })
      } else {
        this.loading = true
        this.$http.post('/user/email', {
          email: this.email,
        }).then(() => {
          this.$toast({
            msg: this.$i18n.t('emailPanel.emailReqSuccTip'),
          })
          this.hasSend = true
        }).finally(() => {
          this.loading = false
        })
      }
    },
  },
}
</script>

<style lang="scss" scope>
.email-icon{
  height: 63px;
  width: 63px;
  display: block;
  margin: 54px auto 10px;
}
.email-con{
  .send-tip{
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #585858;
    margin-bottom: 8px;
  }
  .send-tip_2{
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #9B9B9B;
    margin-bottom: 32px;
  }
  .btn{
    /* Rectangle 138 */
    width: 293px;
    height: 54px;
    background: linear-gradient(135deg, #FCCF31 0%, #F5A255 100%);
    border-radius: 32px;
    line-height: 54px;
    height: 54px;
    color: #fff;
    display: block;
    margin:0 auto;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    &:hover{
      background: linear-gradient(135deg, #ffce1e 0%, #f99940 100%);
    }
  }
  .send{
    display: flex;
    justify-content: center;
    flex-direction: column;
    .input{
      /* Rectangle 277 */
      width: 303px;
      height: 54px;
      margin: 0 auto 38px;
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      box-sizing: border-box;
      border-radius: 16px;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
      &:focus{
        border: 1px solid #afafaf;
        outline: none;
      }
    }

  }
  .tip{
    text-align: center;
    font-size: 14px;
    padding-top: 6px;
    .text{
      color: #AAAAAA;
      line-height: 20px;
      margin-bottom: 8px;
    }
    .btn_2{
      color: #3483FF;
      line-height: 20px;
      margin-top: 10px;
      margin-bottom: 32px;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
    // .btn_2{
    //   margin-top: 10px;
    // }
  }
}
</style>
